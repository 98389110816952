<template>
    <div class="con-wrap">
      <!-- 신청서 팝업 -->
      <HolidayAddPopup :idx="pop.idx" v-if="pop.isShowView" @close="pop.hideView"></HolidayAddPopup>
        <CarrotTitle title="공휴일관리">

        </CarrotTitle>
        <div class="board">
            <div v-if="bbs.isAuth=='Y'" class="mt-40">
                <div>

                    <div class="mb-20">
                        <button class="btn-default float-right" @click="pop.showView(0)">공휴일 등록</button>
                        <button class="btn-default float-right" @click="bbs.delHoliday">공휴일 삭제</button>
                        <div class="clear"></div>
                    </div>
                    
                    <table class="table-col">
                        <colgroup>
                            <col width="40">
                            <col width="80">
                            <col width="100">
                            <col width="180">
                            <col width="*">
                            <col width="120">
                            <col width="180">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                  <label><input type="checkbox" v-model="bbs.isAll" @change="bbs.checkAll"></label>
                                </th>
                                <th>No</th>
                                <th>휴일</th>
                                <th>제목</th>
                                <th>내용</th>
                                <th>등록자</th>
                                <th>등록일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>
                                  <label><input type="checkbox" v-model="bbs.selected" :value="irow.hdate"></label>
                                </td>
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.hdate}}</td>
                                <td><span>{{ irow.title }}</span></td>
                                <td><span>{{ irow.contents }}</span></td>
                                <td>{{ irow.ename }}({{ irow.kname }})</td>
                                <td>{{ irow.wdate }}</td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="7">내역이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
// import JobRotationPopup from '@/components/popup/myPIMS/JobRotationPopup.vue'

import HolidayAddPopup from '@/components/popup/myPIMS/HolidayAddPopup.vue'
import { useStore } from 'vuex';


export default {
    layout:"myPIMS",

    components: {
      HolidayAddPopup,
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();
        const store  = useStore();

        const pop = reactive({
            idx : 0,
            // Popup 노출 여부
            isShowView : false,
            isShowApplList : false,
            isShowApplView : false,

            showView : (idx) => {
                pop.idx = idx;
                pop.isShowView = true;
            },

            hideView : () => {
                pop.idx = 0;
                pop.isShowView = false;

                bbs.doSearch();
            },

            showApplicant : (idx) => {
                pop.idx = idx;
                pop.isShowApplList = true;
            },

            hideApplicant : () => {
                pop.idx = 0;
                pop.isShowApplList = false;

                bbs.doSearch();
            }
        });

        const bbs = reactive({
            isAuth : "N",

            page : 1,
            rows : 20,
            list : [], total : 0,

            state : '',
            selected : [],
            doInit : () => {
                bbs.page = 1;
                bbs.doSearch();
            },

            doSearch : () => {
                let params = {
                    page   : bbs.page,
                    rows   : bbs.rows,
                    state  : bbs.state
                };
                axios.get('/rest/mypims/getHolidayList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            checkAll : () => {
              bbs.selected = [];
              if( bbs.isAll == true ){
                for(let i=0; i<bbs.list.length; i++){
                  let irow = bbs.list[i];
                  bbs.selected.push(irow.hdate);
                }
              }
            },
            delHoliday : () => {
              if( bbs.selected.length <= 0 ){
                Swal.fire({
                  title : '공휴일 관리',
                  text  : '삭제할 대상을 선택해 주세요.'
                });
                return;
              }

              let params = {
                selected : bbs.selected
              };
              axios.post("/rest/mypims/delHoliday", params).then((res) => {
                if( res.data.err == 0 ){
                  Swal.fire({
                    title : '공휴일 관리',
                    text  : '삭제되었습니다'
                  }).then(() => {
                    bbs.selected = [];
                    bbs.doSearch();
                  });
                } else {
                  console.info(res.data.err_msg);
                }
              });
            },
        });

        onMounted(() => {
            // Mounted
            if( store.state.pd_auth == false) {
                router.go(-1);
                Swal.fire({
                    title : 'Job Rotation 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }
            if( store.state.pd_auth == true ) {
              bbs.isAuth = "Y";
            }

            bbs.doInit();
        });

        return {pop, bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>