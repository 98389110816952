<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">공휴일 등록 </p>
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view">
                    <colgroup>
                        <col width="100">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>공휴일</th>
                            <td>
                                <carrot-date-picker v-model="pop.info.hdate" class="float-left"></carrot-date-picker>
                            </td>
                        </tr>
                        <tr>
                            <th>공휴일 제목</th>
                            <td>
                                <input v-model="pop.info.title" type="text" class="w-100per h-30px" placeholder="제목을 입력하세요.">
                            </td>
                        </tr>
                        <tr>
                          <th>공휴일 내용</th>
                          <td>
                            <input v-model="pop.info.contents" type="text" class="w-100per h-30px" placeholder="내용을 입력하세요.">
                          </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table">
                <button class="btn-default float-left"  @click="pop.onClose">취소</button>
                <button class="btn-default float-right" @click="pop.doSubmit">등록</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2';
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import { useStore } from 'vuex';


export default {
    name: 'JobRotationEditPopup',
    components: {
        CarrotDatePicker
    },
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        }
    },
    setup(props, {emit}) {
        const toast = useToast()
        const store  = useStore();

        const pop = reactive({
            idx  : 0,
            info : {
                hdate : "",
                title : "",
                contents : ""
            },
            doSubmit : () => {
                let params = {
                    hdate : pop.info.hdate,
                    contents : pop.info.contents,
                    title : pop.info.title
                };
                console.log(params);

                if( !params.hdate || !params.title || !params.contents ){
                    alert("모든 항목을 작성해주세요.");
                    return;
                }

                axios.post('/rest/mypims/addHoliday', params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.info("등록되었습니다.");
                        pop.onClose();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            if( store.state.pd_auth != true ) {
                pop.onClose();
                Swal.fire({
                    title : '공휴일 등록',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>